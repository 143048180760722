<template>
  <div>
    <a-row :gutter="[4, 4]">
      <a-col :span="6">
        <a-card title="生产线 A" size="small" style="width: 100%; height: 160px">
          <div>
            <span style="margin-right: 12px">PLC 状态:</span>
            <a-badge
              v-if="productionLineStatus.production_line_status_a"
              status="error"
              :text="productionLineStatus.production_line_status_a"
            />
            <a-badge v-else status="success" text="正常" />
          </div>

          <a-button type="primary" style="margin-top: 8px" :loading="startLoading" @click="startHeartbeat('a')">
            启动 PLC 心跳
          </a-button>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card title="生产线 B" size="small" style="width: 100%; height: 160px">
          <div>
            <span style="margin-right: 12px">PLC 状态:</span>
            <a-badge
              v-if="productionLineStatus.production_line_status_b"
              status="error"
              :text="productionLineStatus.production_line_status_b"
            />
            <a-badge v-else status="success" text="正常" />
          </div>

          <a-button type="primary" style="margin-top: 8px" :loading="startLoading" @click="startHeartbeat('b')">
            启动 PLC 心跳
          </a-button>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card title="分拣统计" size="small" style="width: 100%; height: 160px">
          <a-row style="text-align: center; margin-top: 12px">
            <a-col span="12">
              <div style="font-size: 32px; font-weight: bold">
                {{ overviewItem.today_sorting_quantity }}
              </div>
              <div>今日分拣数量</div>
            </a-col>
            <a-col span="12">
              <div style="font-size: 32px; font-weight: bold">
                {{ (overviewItem.today_sorting_volume / 1000000000).toFixed(3) }}
              </div>
              <div>今日分拣体积(m³)</div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card title="在库统计" size="small" style="width: 100%; height: 160px">
          <a-row style="text-align: center; margin-top: 12px">
            <a-col span="12">
              <div style="font-size: 32px; font-weight: bold">
                {{ overviewItem.total_unsorting_quantity }}
              </div>
              <div>在库数量</div>
            </a-col>
            <a-col span="12">
              <div style="font-size: 32px; font-weight: bold">
                {{ overviewItem.yesterday_unsorting_quantity }}
              </div>
              <div>昨日剩余数量</div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card title="米仓回传统计" size="small" style="width: 100%; height: 160px">
          <template slot="extra">
            <a-date-picker
              v-model="micangUploadStatsDate"
              size="small"
              :allowClear="false"
              valueFormat="YYYY-MM-DD"
              style="margin: -2px 0"
              @change="getMiCangUploadStatus"
            />
          </template>

          <a-row style="text-align: center; margin-top: 12px">
            <a-col span="8">
              <div style="font-size: 32px; font-weight: bold">
                {{ micangUploadStatus.waiting_count }}
              </div>
              <div>等待上传</div>
            </a-col>
            <a-col span="8">
              <div style="font-size: 32px; font-weight: bold">
                {{ micangUploadStatus.success_count }}
              </div>
              <div>上传成功</div>
            </a-col>
            <a-col span="8">
              <div style="font-size: 32px; font-weight: bold">
                {{ micangUploadStatus.failure_count }}
              </div>
              <div>上传失败</div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card title="希音回传统计" size="small" style="width: 100%; height: 160px">
          <template slot="extra">
            <a-date-picker
              v-model="sheinUploadStatsDate"
              size="small"
              :allowClear="false"
              valueFormat="YYYY-MM-DD"
              style="margin: -2px 0"
              @change="getSheinUploadStatus"
            />
          </template>

          <a-row style="text-align: center; margin-top: 12px">
            <a-col span="8">
              <div style="font-size: 32px; font-weight: bold">
                {{ sheinUploadStatus.waiting_count }}
              </div>
              <div>等待上传</div>
            </a-col>
            <a-col span="8">
              <div style="font-size: 32px; font-weight: bold">
                {{ sheinUploadStatus.success_count }}
              </div>
              <div>上传成功</div>
            </a-col>
            <a-col span="8">
              <div style="font-size: 32px; font-weight: bold">
                {{ sheinUploadStatus.failure_count }}
              </div>
              <div>上传失败</div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>

      <a-col :span="12">
        <a-card title="今日 Airport 分组统计" size="small" style="width: 100%">
          <a-list :dataSource="overviewItem.airport_stats" style="height: 300px; overflow: auto">
            <a-list-item slot="renderItem" slot-scope="item, index">
              <div style="width: 100%">
                <span>{{ item.airport_id }}</span>
                <div style="float: right; margin-right: 16px">
                  <a-space :size="12">
                    <span>总数量: {{ item.total_quantity }} 件</span>
                    <span>总重量: {{ item.total_weight.toFixed(2) }} 千克</span>
                    <span>总体积: {{ (item.total_volume / 1000000000).toFixed(3) }} 立方米</span>
                  </a-space>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </a-card>
      </a-col>

      <a-col :span="12">
        <a-card title="今日道口分组统计" size="small" style="width: 100%">
          <a-list :dataSource="overviewItem.crossing_stats" style="height: 300px; overflow: auto">
            <a-list-item slot="renderItem" slot-scope="item, index">
              <div style="width: 100%">
                <span>{{ item.crossing_name }}</span>
                <div style="float: right; margin-right: 16px">
                  <a-space :size="12">
                    <span>总数量: {{ item.total_quantity }} 件</span>
                    <span>总重量: {{ item.total_weight.toFixed(2) }} 千克</span>
                    <span>总体积: {{ (item.total_volume / 1000000000).toFixed(3) }} 立方米</span>
                  </a-space>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { plcStatus, plcStart, homeOverview, micangUploadStatus, sheinUploadStatus } from "@/apis/stats";
import moment from "moment";

export default {
  data() {
    return {
      intervalId: null,
      productionLineStatus: {
        production_line_status_a: null,
        production_line_status_b: null,
      },

      startLoading: false,
      overviewItem: {
        airport_stats: [],
        crossing_stats: [],
        today_sorting_quantity: 0,
        today_sorting_volume: 0,
        total_unsorting_quantity: 0,
        yesterday_unsorting_quantity: 0,
      },
      sortingCount: 0,

      micangUploadStatsDate: moment().format("YYYY-MM-DD"),
      micangUploadStatus: { waiting_count: 0, success_count: 0, failure_count: 0 },

      sheinUploadStatsDate: moment().format("YYYY-MM-DD"),
      sheinUploadStatus: { waiting_count: 0, success_count: 0, failure_count: 0 },
    };
  },
  components: {},
  computed: {},
  methods: {
    checkStatus() {
      plcStatus().then((data) => {
        this.productionLineStatus = data;
      });

      homeOverview().then((data) => {
        this.overviewItem = data;
      });

      this.getMiCangUploadStatus();
      this.getSheinUploadStatus();
    },
    getMiCangUploadStatus() {
      micangUploadStatus({ stats_date: this.micangUploadStatsDate }).then((data) => {
        this.micangUploadStatus = data;
      });
    },
    getSheinUploadStatus() {
      sheinUploadStatus({ stats_date: this.sheinUploadStatsDate }).then((data) => {
        this.sheinUploadStatus = data;
      });
    },
    startHeartbeat(productionLine) {
      this.startLoading = true;
      plcStart({ production_line: productionLine }).finally(() => {
        this.$message.success("启动成功");
        this.startLoading = false;
      });
    },
  },
  mounted() {
    this.checkStatus();
    this.intervalId = setInterval(() => {
      this.checkStatus();
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
